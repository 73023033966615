.tennis-background {
    width: 100%;
    height: 300px; /* Adjust the height as needed */
    background: linear-gradient(135deg, #a0ff7f 30%, #7fd96b 70%); /* Softer green colors */
    position: relative;
    overflow: hidden;
}

@media print {
    .genesys-messenger {
        display:none !important;
    }
}

// Print-specific spacing classes for Vuetify applications

// Define the spacings
$spacings: (
  0: 0,
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px,
  6: 24px,
  8: 32px,
  10: 40px,
  12: 48px,
  16: 64px
);

// Define the directions
$directions: (
  t: top,
  b: bottom,
  l: left,
  r: right,
  x: (left, right),
  y: (top, bottom),
  a: (top, bottom, left, right)
);

// Media query for print
@media print {
  // Increase specificity by using .v-application
  .v-application {
    // Generate margin classes
    @each $key, $value in $spacings {
      @each $dir-key, $dir-value in $directions {
        .m#{$dir-key}-print-#{$key} {
          @if type-of($dir-value) == list {
            @each $d in $dir-value {
              margin-#{$d}: $value !important;
            }
          } @else {
            margin-#{$dir-value}: $value !important;
          }
        }
      }
    }

    // Generate padding classes
    @each $key, $value in $spacings {
      @each $dir-key, $dir-value in $directions {
        .p#{$dir-key}-print-#{$key} {
          @if type-of($dir-value) == list {
            @each $d in $dir-value {
              padding-#{$d}: $value !important;
            }
          } @else {
            padding-#{$dir-value}: $value !important;
          }
        }
      }
    }


  }
}
