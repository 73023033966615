// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import "~animate.css/animate.css";

/*------------------------------------*\
    Global Variables
\*------------------------------------*/
:root {
    /* - Colors */
    --directv-blue: #196DD4;
    --directv-light-darkGray: #FBFBFB;
}

